import { Editor } from 'slate';
import { isURLImage } from 'utils/url';
import { fileImageToBase64static } from 'utils/file';
import { RichTextVoid } from '../../components/RichTextVoid/RichTextVoid';
import { ISlateElementImage, SlateElementTypes, SlateRenderElementProps } from '../../RichText.interface';
import { RichTextEditor } from '../RichText.common';

export function RichTextImage({ attributes, element, children }: Partial<SlateRenderElementProps<ISlateElementImage>>) {
  return (
    <RichTextVoid element={element} attributes={attributes}>
      {children}
      <img src={element.url} alt="" />
    </RichTextVoid>
  );
}

export function createElementImage(url: string, name?: string): ISlateElementImage {
  const result: ISlateElementImage = {
    type: SlateElementTypes.Image,
    url,
    children: [{ text: '' }],
  };
  if (name) {
    result.name = name;
  }
  return result;
}

function insertElementImage(editor: Editor, url: string, name?: string) {
  editor.insertNode(createElementImage(url, name));
  RichTextEditor.moveForward(editor);
}

export function insertImagesFromFiles(editor: Editor, files: FileList) {
  for (const file of files) {
    fileImageToBase64static(file, (url: string, name: string) => {
      insertElementImage(editor, url, name);
    });
  }
}

export function withImage(editor: Editor): Editor {
  const { insertData } = editor;

  editor.insertData = async (data) => {
    // parse files
    const { files } = data;
    if (files && files.length > 0) {
      insertImagesFromFiles(editor, files);
      return;
    }

    // parse text
    const text = data.getData('text/plain');
    if (isURLImage(text)) {
      insertElementImage(editor, text);
      return;
    }

    insertData(data);
  };

  return editor;
}
