import { TreeSelect } from 'antd';
import { IRole } from 'features/Role';
import type { DefaultOptionType as ITreeNode } from 'rc-tree-select/lib/TreeSelect';
import React, { useCallback, useMemo } from 'react';
import { arraySortBy } from 'utils/array';
import { DIRECTORATE_SLUG_HEAD } from '../../../Directorate/Directorate.model';
import { useStationListLoadQuery } from '../../Station.api';
import { IStation } from '../../Station.interface';
import { getStationRoleAdmin } from '../../Station.model';

const CITY_PREFIX = 'city-';
const STATION_PREFIX = 'station-';
const ROLE_PREFIX = 'roles_';

export function createTreeNode(title: string, value: string, children?: ITreeNode[]): ITreeNode {
  return children
    ? { title, value, children }
    : { title, value };
}

function insertRole(cityList: ITreeNode[], cityName: string, stationName: string, role: string): ITreeNode[] {
  // find or create city node
  const cityValue = `${CITY_PREFIX}${cityName}`;
  let city = cityList.find((item) => item.value === cityValue);
  if (!city) {
    city = createTreeNode(cityName, cityValue, []);
    cityList.push(city);
    cityList.sort(arraySortBy('title'));
  }
  // find or create station node
  const stationList = city.children;
  let station = stationList.find((item) => item.value === `${STATION_PREFIX}${stationName}`);
  if (!station) {
    // insert with roles
    station = createTreeNode(stationName, role);
    stationList.push(station);
  }
  return cityList;
}

function mapStationToRoleList(stationList: IStation[], roleList: IRole[]): string[] {
  return stationList
    .map((item) => item.slug)
    .reduce((memo, slug) => [
      ...memo,
      ...roleList
        .filter((item) => item.name === getStationRoleAdmin(slug))
        .map((item) => item.name),
    ], []);
}

export function StationRoleSelector({ roles, onChange, value, directorateList }: {
  value?: string[];
  roles: IRole[];
  onChange?: (value: string[]) => any;
  directorateList: string[];
}) {
  const { data = [], isLoading } = useStationListLoadQuery();
  const treeValue = useMemo(() => isLoading ? [] : value, [isLoading, value]);
  const filtered = useMemo(() => data, [data]);
  const isSuperAdmin = useMemo(() => directorateList.includes(DIRECTORATE_SLUG_HEAD), [directorateList]);

  const tree = useMemo(() => {
    if (isLoading) {
      return [];
    }

    const cityNodes: ITreeNode[] = [];
    filtered
      .forEach((station) => {
        const roleAdmin = roles.find((item) => item.name === getStationRoleAdmin(station.slug));
        // prepare role nodes for insert
        const roleList = [];
        if (roleAdmin) {
          roleList.push(createTreeNode(`${station.info.name}`, roleAdmin.name));
          insertRole(cityNodes, station.info.city, station.info.name, roleAdmin.name);
        }
      });

    return cityNodes;
  }, [filtered, isLoading, roles]);

  const handleChange = useCallback((values: string[]) => {
    const result = values.reduce<string[]>((memo, value) => {
      // role
      if (value.startsWith(ROLE_PREFIX)) {
        return [...memo, value];
      }
      // filter by city
      if (value.startsWith(CITY_PREFIX)) {
        const stationList = filtered.filter((item) => item.info.city === value.substring(CITY_PREFIX.length));
        return [
          ...memo,
          ...mapStationToRoleList(stationList, roles),
        ];
      }
      // filter by station
      if (value.startsWith(STATION_PREFIX)) {
        const stationList = data.filter((item) => item.info.name === value.substring(STATION_PREFIX.length));
        return [
          ...memo,
          ...mapStationToRoleList(stationList, roles),
        ];
      }
      return memo;
    }, []);
    return onChange(result);
  }, [onChange, filtered, roles, data]);

  return (
    <TreeSelect
      value={treeValue}
      treeData={tree}
      onChange={handleChange}
      treeCheckable
      showCheckedStrategy={TreeSelect.SHOW_PARENT}
      placeholder={isSuperAdmin && 'Все вокзалы'}
      loading={isLoading}
      disabled={isSuperAdmin}
    />
  );
}
