import { UploadFile } from 'antd';
import { LanguageTypes } from 'features/Language';

export enum ServiceType {
  Simple = 'simple',
  Premium = 'premium',
  Flagship = 'flagship',
}

export enum ServiceParentSourcesTypes {
  Station = 'station',
  Service = 'service',
}

export interface IServiceBase {
  id: number;
  slug: string;
  gis_id: string;
  service_catalog_id: string;
  icon: string;
  info: IServiceBaseInfo;
  external_link: string;
}

export interface IServiceBaseInfo {
  title: string;
  content: string;
}

// premium and flagship
export interface IServiceComplex extends IServiceBase {
  attachments: string[];
  is_station_list: boolean;
}

// form

export interface IServiceFormBase {
  icon: UploadFile[];
  title: string;
  content: string;
  gisId: string;
  catalogId: string;
  externalLink: string;
}

export interface IServiceComplexForm extends IServiceFormBase {
  banner: UploadFile[];
  isStationList: boolean;
}

export const serviceFormBaseEmpty: IServiceFormBase = {
  icon: [],
  title: '',
  content: '',
  gisId: '',
  catalogId: '',
  externalLink: '',
};

export const serviceComplexFormEmpty: IServiceComplexForm = {
  ...serviceFormBaseEmpty,
  banner: [],
  isStationList: false,
};


// api

export interface IServiceMutationBodyBase {
  gis_id: string;
  service_catalog_id: string;
}

export interface IServiceBaseUpdateRequest extends IServiceMutationBodyBase {
  slug: string;
}

export interface IServiceComplexInfoBaseUpdateRequest extends IServiceBaseInfo {
  slug: string;
  lang: LanguageTypes;
}

export enum ServiceFormTabTypes {
  BasicForm = 'basic',
  StationTable = 'station'
}

export interface IStationLoader {
  label: string;
  labelCreateTitle: string;
  labelCreateSuccess: string;
  labelEditTitle: string;
  labelEditSuccess: string;
  useListLoad: any;
  useItemLoad: (slug: string) => any;
  useItemCreate: any;
  useItemRemove: any;
  useItemUpdate: any;
  useItemInfoUpdate: any;
}
