import { ExportOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { APP_PUBLIC_URL, featureFlags } from 'App.environment';
import { FormOption } from 'features/Form';
import { clientMediaUpload, createUploadFile } from 'features/Media';
import type { KeycloakRoles } from 'keycloak-js';
import { Link } from 'react-router-dom';
import { createURL } from 'utils/url';
import { PageBlockForm } from './components/PageBlockForm/PageBlockForm';
import { PageBlockFormButton } from './components/PageBlockForm/PageBlockFormButton';
import { PageBlockFormCarousel } from './components/PageBlockForm/PageBlockFormCarousel';
import { PageBlockFormImage } from './components/PageBlockForm/PageBlockFormImage';
import { PageBlockFormMenu } from './components/PageBlockForm/PageBlockFormMenu';
import { PageBlockFormText } from './components/PageBlockForm/PageBlockFormText';
import { PagePageForm } from './components/PagePageForm/PagePageForm';
import { PagePageFormBasic } from './components/PagePageForm/PagePageFormBasic';
import { PagePageFormInternal } from './components/PagePageForm/PagePageFormInternal';
import { IMenuItem, IMenuItemLink, IPage, IPageBlockCarouselItem, IPageBlockCarouselItemUploaded, IPageBlockTemplateConfig, IPageConfig, IPagePageTemplateConfig, MenuItemTypes, PageBlockTemplateTypes, PagePageTemplateTypes, PageViewTypes } from './Page.interface';

export const pagePageTemplateConfigMap: Record<PagePageTemplateTypes, IPagePageTemplateConfig> = {
  [PagePageTemplateTypes.Basic]: {
    label: 'Основной',
    Form: PagePageFormBasic,
  },
  [PagePageTemplateTypes.Internal]: {
    label: 'Внутренний',
    Form: PagePageFormInternal,
  },
};

export const pageBlockTemplateConfigMap: Record<PageBlockTemplateTypes, IPageBlockTemplateConfig> = {
  [PageBlockTemplateTypes.Text]: {
    label: 'Текст',
    Form: PageBlockFormText,
  },
  [PageBlockTemplateTypes.Phone]: {
    label: 'Телефон',
    Form: PageBlockFormText,
  },
  [PageBlockTemplateTypes.Image]: {
    label: 'Картинка',
    Form: PageBlockFormImage,
  },
  [PageBlockTemplateTypes.Button]: {
    label: 'Кнопка',
    Form: PageBlockFormButton,
  },
  [PageBlockTemplateTypes.Carousel]: {
    label: 'Карусель',
    Form: PageBlockFormCarousel,
    contentParser: pageBlockCarouselContentParser,
    contentSerializer: pageBlockCarouselUploadFiles,
  },
  [PageBlockTemplateTypes.Menu]: {
    label: 'Меню',
    Form: PageBlockFormMenu,
    contentParser: pageBlockMenuContentParser,
    contentSerializer: pageBlockMenuContentSerializer,
  },
};

export function createPageUrl(template: IPage['template'], slug: IPage['slug'], link: IPage['link'], prefix = '') {
  switch (template) {
    case PagePageTemplateTypes.Basic:
      return `${prefix}/pages/${slug}`;
    case PagePageTemplateTypes.Internal:
      return `${prefix}${link}`;
    default:
      return '';
  }
}

export const pageTableColumnListPage: ColumnsType<IPage> = [
  {
    title: 'Название',
    dataIndex: ['info', 'title'],
    render: (title, item) => (
      <Link to={`/pages/${PageViewTypes.Page}/${item.slug}`}>{title}</Link>
    ),
  },
  {
    title: 'Тип',
    dataIndex: 'template',
    render: (template: PagePageTemplateTypes) => (
      <div>{pagePageTemplateConfigMap[template].label}</div>
    ),
  },
  {
    title: 'Просмотр',
    dataIndex: 'slug',
    render: (slug, model) => {
      return (
        <Link to={createPageUrl(model.template, model.slug, model.link, APP_PUBLIC_URL)} target="_blank">
          <ExportOutlined />
        </Link>
      );
    },
  },
];

export const pageTableColumnListBlock: ColumnsType<IPage> = [
  {
    title: 'Описание',
    dataIndex: ['info', 'title'],
    defaultSortOrder: 'ascend',
    showSorterTooltip: false,
    sorter: (a, b) => a.info.title < b.info.title ? -1 : 1,
    render: (title, item) => (
      <Link to={`/pages/${PageViewTypes.Block}/${item.slug}`}>{title}</Link>
    ),
  },
];

if (featureFlags.isDev) {
  pageTableColumnListBlock.push({
    title: 'id',
    dataIndex: 'slug',
  });
  pageTableColumnListBlock.push({
    title: 'Тип',
    dataIndex: 'template',
    render: (template: PagePageTemplateTypes) => (
      <div>{pageBlockTemplateConfigMap[template].label}</div>
    ),
  });
}

export const pagePageTemplateOptions: FormOption[] = Object.values(PagePageTemplateTypes).map((item): FormOption => ({
  label: pagePageTemplateConfigMap[item].label,
  value: item,
}));

export const pageBlockTemplateOptions: FormOption[] = Object.values(PageBlockTemplateTypes).map((item): FormOption => ({
  label: pageBlockTemplateConfigMap[item].label,
  value: item,
}));

export const pageTableUrl = (tab: PageViewTypes = PageViewTypes.Page) => createURL(`/pages`, { tab });
export const pageEditUrl = (type: PageViewTypes, slug: string) => createURL(`/pages/${type}/${slug}`);

export const pageConfig: Record<PageViewTypes, IPageConfig> = {
  [PageViewTypes.Page]: {
    createLabel: 'Создать страницу',
    createUrl: `/pages/${PageViewTypes.Page}/create`,
    createSuccess: 'Страница создана',
    tableColumnList: pageTableColumnListPage,
    updateSuccess: 'Страница обновлена',
    removeSuccess: 'Страница удалена',
    editLabel: 'Редактирование страницы',
    Form: PagePageForm,
    formTemplateOptions: pagePageTemplateOptions,
  },
  [PageViewTypes.Block]: {
    createLabel: 'Создать блок',
    createUrl: `/pages/${PageViewTypes.Block}/create`,
    createSuccess: 'Блок создан',
    tableColumnList: pageTableColumnListBlock,
    updateSuccess: 'Блок обновлен',
    removeSuccess: 'Блок удален',
    editLabel: 'Редактирование блока',
    Form: PageBlockForm,
    formTemplateOptions: pageBlockTemplateOptions,
  },
};

const PAGE_ROLE_ADMIN = 'roles_pages_admin';

export const PAGE_INTERNAL_SLUG_PREFIX = 'page-internal';

export function canViewPages(roles: KeycloakRoles['roles']) {
  return roles.includes(PAGE_ROLE_ADMIN);
}

// block templates

export const PAGE_BLOCK_LINK_NONE = 'none';

export const PAGE_BLOCK_CONTENT_FIELD = 'content';

export class PageBlockUploadError extends Error {
  constructor() {
    super('Ошибка при загрузке изображений');
  }
}

async function pageBlockCarouselUploadFile(model: IPageBlockCarouselItem): Promise<IPageBlockCarouselItemUploaded> {
  const { banner, ...rest } = model;
  const file = banner[0];
  // skip
  if (file?.url) {
    return {
      banner: file.url,
      ...rest,
    };
  }
  // upload
  const link = await clientMediaUpload('pages', file.originFileObj);
  return {
    banner: link,
    ...rest,
  };
}

export async function pageBlockCarouselUploadFiles(model: IPageBlockCarouselItem[]): Promise<string> {
  if (!model) {
    return JSON.stringify([]);
  }

  try {
    const promises = model.map(pageBlockCarouselUploadFile);
    const result = await Promise.all(promises);
    return JSON.stringify(result);
  } catch (e) {
    console.error('error parsing carousel images', e);
    throw new PageBlockUploadError();
  }
}

export function pageBlockCarouselContentParser(value: string): IPageBlockCarouselItem[] {
  try {
    const result = (JSON.parse(value) || []) as IPageBlockCarouselItemUploaded[];
    return result.map((item) => ({
      ...item,
      banner: [createUploadFile(item.banner, 0)],
    }));
  } catch (e) {
    console.error('error parse carousel', e);
    return [];
  }
}

// menu

export function pageSlugToMenuItem(slug: IPage['slug'], pageList: IPage[]): IMenuItemLink {
  const page = pageList.find((item) => item.slug === slug)!;
  return {
    type: MenuItemTypes.Link,
    label: page?.info?.title || 'Страница не найдена',
    img: page?.info?.attachments?.[0],
    url: page.link,
    slug,
  };
}

export function pageBlockMenuContentParser(value: string): IMenuItem[] {
  try {
    return JSON.parse(value) || [];

  } catch (e) {
    console.error('error parsing block menu', e);
    return [];
  }
}

export function pageBlockMenuContentSerializer(model: IMenuItem[]): string {
  return JSON.stringify(model || []);
}
