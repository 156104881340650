import { UploadFile } from 'antd';
import { ContactGroups } from 'features/Contact';
import { LanguageTypes } from 'features/Language';
import { UseParamsBase } from 'features/Router';
import { IServiceBase, IServiceComplex, ServiceType } from 'features/Service';

export interface IStationServiceInfo {
  lang: LanguageTypes;
  title: string;
  contact_groups: ContactGroups[];
  content: string;
}

export interface IStationService {
  id: number;
  station_id: number;
  service_id: number;
  type: ServiceType;
  external_link: string;
  info: IStationServiceInfo;
  service_base_info: IServiceBase | IServiceComplex;
  attachments: string[];
}

export interface IStationServiceSimple {
  id: number;
  station_service_id: number;
  simple_service_id: number;
  info: IStationServiceSimpleInfo;
  service_base_info: IServiceBase;
}

export interface IStationServiceSimpleInfo {
  title: string;
  content: string;
}

export interface StationServiceEditParams extends UseParamsBase {
  serviceType: ServiceType;
  serviceSlug: string;
  stationSlug: string;
}

export interface StationServiceSimpleCreateParams extends UseParamsBase {
  serviceType: ServiceType;
  serviceSlug: string;
  stationSlug: string;
}

export interface StationServiceSimpleEditParams extends StationServiceSimpleCreateParams {
  id: string;
}

export interface IStationServiceTableItem {
  id: number;
  station: string;
  title: string;
  url: string;
  previewUrl: string;
}

// form

export interface IStationServiceForm {
  title: string;
  content: string;
  stationId: number;
  serviceId: number;
  externalLink: string;
  contacts: ContactGroups[];
  banner: UploadFile[];
}

export interface IStationServiceSimpleForm {
  content: string;
  simpleId: number;
}

export const stationServiceFormEmpty: IStationServiceForm = {
  title: '',
  content: '',
  stationId: null,
  serviceId: null,
  externalLink: '',
  contacts: [],
  banner: [],
};

// api

export interface StationServiceListRequest {
  station_slug?: string;
  service_type?: ServiceType;
  service_id?: number;
}

export interface StationServiceCreateRequest {
  station_id: number;
  service_id: number;
  external_link: string;
  type: ServiceType;
  attachments: string[];
}

export interface StationServiceCreateResponse extends StationServiceCreateRequest {
  id: number;
}

export interface StationServiceUpdateRequest {
  external_link: string;
  stationServiceId: IStationService['id'];
  attachments: IStationService['attachments'];
}

export interface StationServiceInfoUpdateRequest extends IStationServiceInfo {
  serviceId: number;
  lang: LanguageTypes;
}

// api for simple

export interface StationServiceSimpleMutationRequest {
  station_service_id: number;
  simple_service_id: number;
}

export interface StationServiceSimpleCreateResponse extends StationServiceSimpleMutationRequest {
  id: number;
}

export interface StationServiceSimpleInfoUpdateRequest extends IStationServiceSimpleInfo {
  station_service_id: number;
  simple_service_id: number;
  lang: LanguageTypes;
}
