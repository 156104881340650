import { clientMediaUpload } from 'features/Media';
import { ISlateDescendant, ISlateElement, ISlateElementImg, ISlateElementP, ISlateText, SlateElementTypes } from 'features/RichText';
import { fileBase64ToFile } from 'utils/file';
import { promiseMap } from 'utils/promise';

export function createElementP(text: string = ''): ISlateElementP {
  return {
    type: SlateElementTypes.P,
    children: [{ text }],
  };
}

// substitute types:
// 'paragraph' -> 'p'
// 'image' -> 'img'
// 'link' -> 'a'
export function richText2FixP(value: ISlateDescendant[]) {
  return value.map((item) => {
    const { id, ...rest } = item as ISlateElement;
    const el = rest as ISlateElement;
    if (el.type === SlateElementTypes.Paragraph) {
      // @ts-ignore
      el.type = SlateElementTypes.P;
    }
    if (el.type === SlateElementTypes.Image) {
      // @ts-ignore
      el.type = SlateElementTypes.Img;
    }
    if (el.type === SlateElementTypes.Link) {
      // @ts-ignore
      el.type = SlateElementTypes.A;
    }
    if (el.children) {
      el.children = richText2FixP(el.children);
    }
    return el;
  });
}

async function richText2UploadFile(prefix: string, element: ISlateElementImg): Promise<ISlateElementImg> {
  const { id, ...rest } = element;
  if (!element.url?.startsWith('data')) {
    return element;
  }
  const file = fileBase64ToFile(element.url, id);
  if (!file) {
    console.error('error file convert', element);
    return element;
  }
  const url = await clientMediaUpload(prefix, file);
  return { ...rest, url };
}

export class RichTextUploadError extends Error {
  constructor() {
    super('Ошибка при загрузке изображений');
  }
}

async function richText2serializeItem(value: ISlateDescendant, prefix: string): Promise<ISlateDescendant> {
  // leaf
  if ((value as ISlateText).text !== undefined) {
    return value;
  }

  // node
  let el = { ...value } as ISlateElement;
  if (el.type === SlateElementTypes.Img) {
    // extract images from content, upload to server and replace data to url values
    el = await richText2UploadFile(prefix, el);
  } else {
    const { id, ...rest } = el;
    el = rest as ISlateElement;
  }

  // children
  const children: ISlateDescendant[] = await promiseMap(el.children, (item) => richText2serializeItem(item, prefix));

  return {
    ...el,
    children,
  };
}

// upload images
export async function richText2SerializeContent(prefix: string, value: string): Promise<string> {
  if (!value) {
    return '';
  }

  try {
    const content: ISlateDescendant[] = JSON.parse(value);
    const result = await promiseMap(content, (item) => richText2serializeItem(item, prefix));
    return JSON.stringify(result);
  } catch (e) {
    console.error('error parsing rich text content images', e);
    throw new RichTextUploadError();
  }
}
