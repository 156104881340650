import { App } from 'antd';
import { LANGUAGE_DEFAULT } from 'features/Language';
import { LayoutContent } from 'features/Layout';
import { UseParamsBase } from 'features/Router';
import { IServiceBase, ServiceType } from 'features/Service/index';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyStationServiceListLoadQuery, useStationServiceRemoveMutation } from '../../../StationService/StationService.api';
import { useServiceItemInfoUpdate, useServiceItemLoad, useServiceItemRemove, useServiceItemUpdate } from '../../Service.hook';
import { serviceCRUDMap, serviceFormBackUrl } from '../../Service.model';
import { ServiceComplexForm } from '../ServiceComplexForm/ServiceComplexForm';

interface ServiceComplexEditParams extends UseParamsBase {
  serviceType: ServiceType;
  serviceSlug: string;
}

export function ServiceComplexEdit() {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { serviceSlug, serviceType } = useParams<ServiceComplexEditParams>();
  const { data, isLoading } = useServiceItemLoad(serviceType, serviceSlug);
  const [serviceInfoUpdate] = useServiceItemInfoUpdate(serviceType);
  const [serviceUpdate] = useServiceItemUpdate(serviceType);
  const [serviceRemove] = useServiceItemRemove(serviceType);
  const backUrl = useMemo(() => serviceFormBackUrl(serviceType), [serviceType]);
  const config = useMemo(() => serviceCRUDMap[serviceType], [serviceType]);
  const [stationServiceListLoad] = useLazyStationServiceListLoadQuery();
  const [stationServiceRemove] = useStationServiceRemoveMutation();

  const handleFinish = useCallback(async (values: IServiceBase) => {
    const { info, ...base } = values;

    await Promise.all([
      serviceUpdate(base).unwrap(),
      serviceInfoUpdate({
        slug: serviceSlug,
        lang: LANGUAGE_DEFAULT,
        ...info,
      }).unwrap(),
    ]);

    message.success(config.labelEditSuccess);
    navigate(backUrl);
  }, [message, config, navigate, backUrl, serviceUpdate, serviceInfoUpdate, serviceSlug]);

  const handleRemove = useCallback(async () => {
    // remove related services on stations
    const stationServiceList = await stationServiceListLoad({
      service_id: data.id,
      service_type: serviceType,
    }).unwrap();
    const promises = stationServiceList.map((item) => stationServiceRemove(item.id).unwrap());
    await Promise.all(promises);
    // remove service
    await serviceRemove(serviceSlug).unwrap();
    navigate(backUrl);
  }, [stationServiceListLoad, data, serviceType, serviceRemove, serviceSlug, navigate, backUrl, stationServiceRemove]);

  return (
    <LayoutContent
      title={config.labelEditTitle}
      backUrl={backUrl}
      isLoading={isLoading}
      render={() => (
        <ServiceComplexForm
          serviceType={serviceType}
          model={data}
          onFinish={handleFinish}
          onRemove={handleRemove}
        />
      )}
    />
  );
}
