import { clientMediaUpload } from 'features/Media';
import { FC } from 'react';
import { fileBase64ToFile } from 'utils/file';
import { RichTextImage } from './plugins/image/RichText.image';
import { RichTextParagraph, RichTextQuote } from './plugins/RichText.common';
import { RichTextYoutube } from './plugins/RichText.youtube';
import { RichTextURL } from './plugins/url/RichText.url';
import { ISlateElement, ISlateElementImage, ISlateElementParagraph, RichTextEditorOptions, SlateElementTypes, SlateRenderElementProps } from './RichText.interface';

export const slateInlineList: SlateElementTypes[] = [
  SlateElementTypes.Link,
];

export const slateVoidList: SlateElementTypes[] = [
  SlateElementTypes.Image,
  SlateElementTypes.Youtube,
];

export function createElementParagraph(text: string = ''): ISlateElementParagraph {
  return {
    type: SlateElementTypes.Paragraph,
    children: [{ text }],
  };
}

export const richTextNodeMap: Record<SlateElementTypes, FC<Partial<SlateRenderElementProps<ISlateElement>>>> = {
  [SlateElementTypes.Paragraph]: RichTextParagraph,
  [SlateElementTypes.P]: RichTextParagraph,
  [SlateElementTypes.Image]: RichTextImage,
  [SlateElementTypes.Img]: RichTextImage,
  [SlateElementTypes.Quote]: RichTextQuote,
  [SlateElementTypes.Link]: RichTextURL,
  [SlateElementTypes.A]: RichTextURL,
  [SlateElementTypes.Youtube]: RichTextYoutube,
};

export const richTextOptionDefault: RichTextEditorOptions = {
  formatting: true,
  url: true,
  youtube: true,
  image: true,
  link: true,
};

async function richTextUploadFile(prefix: string, element: ISlateElementImage): Promise<ISlateElementImage> {
  const { name, ...props } = element;
  if (!element.url?.startsWith('data')) {
    return element;
  }
  const file = fileBase64ToFile(element.url, name);
  if (!file) {
    console.error('error file convert', element);
    return element;
  }
  const url = await clientMediaUpload(prefix, file);
  // el without 'name'
  return { ...props, url };
}

export class RichTextUploadError extends Error {
  constructor() {
    super('Ошибка при загрузке изображений');
  }
}

// extract images from content, upload to server and replace data to url values
export async function richTextUploadFiles(prefix: string, value: string): Promise<string> {
  if (!value) {
    return '';
  }

  try {
    const content: ISlateElement[] = JSON.parse(value);

    // TODO @dkchv: need deeper check if ul/li/tables will be added
    const promises = content.map((item): Promise<ISlateElement> => {
      if (item.type !== SlateElementTypes.Image) {
        return Promise.resolve(item);
      }
      return richTextUploadFile(prefix, item);
    });
    const result = await Promise.all(promises);
    return JSON.stringify(result);
  } catch (e) {
    console.error('error parsing rich text content images', e);
    throw new RichTextUploadError();
  }
}
