import { Flex, Typography } from 'antd';
import { Spinner } from 'features/UI';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useStationListWithRoles } from '../../../Station/Station.hooks';
import { stationSortByName } from '../../../Station/Station.model';
import { IStationService } from '../../../StationService/StationService.intefrace';

export function ServiceStationList({ stationList }: {
  stationList: IStationService[];
}) {
  const { data = [], isLoading } = useStationListWithRoles();

  const filtered = useMemo(() => {
    const idList = stationList.map((item) => item.station_id);
    return data
      .filter((item) => idList.includes(item.id))
      .slice()
      .sort(stationSortByName)
  }, [data, stationList]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Flex gap={8} vertical>
      {filtered.length > 0
        ? filtered.map((item) => (
          <Link key={item.id} to={`/stations/${item.slug}`}>{item.info.name}</Link>
        ))
        : <Typography.Text italic>Не присутствует на вокзалах</Typography.Text>}
    </Flex>
  );
}
