import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { formFilterOptionByLabel, FormOption } from 'features/Form';
import { useMemo } from 'react';
import { useDirectorateListLoadQuery } from '../../Directorate.api';
import { useDirectorateRoles } from '../../Directorate.hook';
import { isDirectorateAdmin } from '../../Directorate.model';

export function DirectorateSelector(props: SelectProps) {
  const { data = [], isLoading } = useDirectorateListLoadQuery();
  const roles = useDirectorateRoles();

  const options = useMemo((): FormOption[] => {
    if (isLoading) {
      return [];
    }
    return data.map((item) => ({
      label: item.info.name,
      value: item.slug,
      disabled: !isDirectorateAdmin(roles, item.slug),
    }));
  }, [isLoading, data, roles]);

  return (
    <Select
      options={options}
      filterOption={formFilterOptionByLabel}
      showSearch
      loading={isLoading}
      {...props}
    />
  );
}
