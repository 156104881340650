import { QueryMethodTypes } from 'features/Query';
import { IServiceBase, IServiceBaseInfo, IServiceBaseUpdateRequest, IServiceComplexInfoBaseUpdateRequest, ServiceType } from './index';
import { serviceBaseApi } from './ServiceBase.api';

const PREMIUM_LIST_TAG = {
  type: ServiceType.Premium,
  id: 'premiumList',
};

const servicePremiumApi = serviceBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // premium service list
    premiumListLoad: builder.query<IServiceBase[], void>({
      query: () => '/premium_services',
      providesTags: (res = []) => [
        PREMIUM_LIST_TAG,
        ...res.map((item) => ({
          type: ServiceType.Premium,
          id: String(item.slug),
        })),
      ],
    }),

    // one premium
    premiumLoad: builder.query<IServiceBase, string>({
      query: (slug) => `/premium_services/${slug}`,
      providesTags: (res, error, req) => error ? [] : [{
        type: ServiceType.Premium,
        id: String(res.slug),
      }],
    }),
    premiumCreate: builder.mutation<IServiceBase, IServiceBaseUpdateRequest>({
      query: (body) => ({
        url: '/premium_services',
        method: QueryMethodTypes.POST,
        body,
      }),
    }),
    premiumInfoUpdate: builder.mutation<IServiceBaseInfo, IServiceComplexInfoBaseUpdateRequest>({
      query: ({ slug, lang, ...body }) => ({
        url: `/premium_services/${slug}/info/${lang}`,
        method: QueryMethodTypes.PATCH,
        body,
      }),
      invalidatesTags: (res, err, req) => err
        ? []
        : [PREMIUM_LIST_TAG, {
          type: ServiceType.Premium,
          id: String(req.slug),
        }],
    }),
    premiumUpdate: builder.mutation<IServiceBase, IServiceBaseUpdateRequest>({
      query: ({ slug, ...body }) => ({
        url: `/premium_services/${slug}`,
        method: QueryMethodTypes.PATCH,
        body,
      }),
      invalidatesTags: (res, err, req) => err
        ? []
        : [PREMIUM_LIST_TAG, {
          type: ServiceType.Premium,
          id: String(req.slug),
        }],
    }),
    premiumRemove: builder.mutation<void, string>({
      query: (slug) => ({
        url: `/premium_services/${slug}`,
        method: QueryMethodTypes.DELETE,
      }),
      invalidatesTags: (res, err) => err ? [] : [PREMIUM_LIST_TAG],
    }),
  }),
});


export const {
  // premium list
  usePremiumListLoadQuery,
  // premium one
  usePremiumLoadQuery,
  usePremiumCreateMutation,
  usePremiumInfoUpdateMutation,
  usePremiumUpdateMutation,
  usePremiumRemoveMutation,
} = servicePremiumApi;
