import { Button, Table } from 'antd';
import { APP_PUBLIC_URL } from 'App.environment';
import { ServiceType } from 'features/Service/index';
import { useMemo } from 'react';
import { useStationListWithRoles } from '../../../Station/Station.hooks';
import { useStationServiceListLoadQuery } from '../../../StationService/StationService.api';
import { IStationServiceTableItem } from '../../../StationService/StationService.intefrace';
import { serviceStationCreateUrl, serviceStationTableColumnList } from '../../Service.model';

export function ServiceStationTable({ serviceType, serviceSlug, serviceId, isList }: {
  serviceType: ServiceType;
  serviceSlug: string;
  serviceId: number;
  isList: boolean;
}) {
  const { data = [], isLoading } = useStationServiceListLoadQuery({
    service_id: serviceId,
    service_type: serviceType,
  });
  const { data: stationList = [], isLoading: isStationListLoading } = useStationListWithRoles();

  const filteredData = useMemo(() => {
    if (isLoading || isStationListLoading) {
      return [];
    }
    return data
      .map((stationService): IStationServiceTableItem => {
        const station = stationList.find((item) => stationService.station_id === item.id);
        // no access (by roles)
        if (!station) {
          return null;
        }
        return {
          id: stationService.id,
          station: station.info.name,
          title: stationService.info.title || 'Без названия',
          url: `/services/${serviceType}/${serviceSlug}/station/${station.slug}`,
          previewUrl: `${APP_PUBLIC_URL}/services/${serviceType}/${serviceSlug}/${station.slug}`
        };
      })
      .filter((item) => !!item);
  }, [data, isLoading, isStationListLoading, serviceSlug, serviceType, stationList]);

  return (
    <div>
      <Button href={serviceStationCreateUrl(serviceType, serviceSlug)} className="mb-6">
        Добавить вокзал
      </Button>
      <Table
        loading={isLoading || isStationListLoading}
        columns={serviceStationTableColumnList(isList)}
        dataSource={filteredData}
        rowKey="id"
        pagination={false}
      />
    </div>
  );
}
